import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from 'App';
import { ProposalProps } from 'app/pages/ProposalPdf/types';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      retryDelay: attemptIndex => 1000 * 2 ** attemptIndex,
      cacheTime: 1000 * 60 * 60, // 60 minutes of cache time
      staleTime: 1000 * 30, // 30 seconds of stale time
    },
  },
});

function Proposals(props: ProposalProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <App {...props} />
    </QueryClientProvider>
  );
}

export default Proposals;
